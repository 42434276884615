import React, { useState, useEffect, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { navigate } from "gatsby";
import styled from "styled-components";

// Context
import { PageColor } from "../../components/context/page-color";
import { HeaderColor } from "../../components/context/header-color";

// SEO
import { PageSeo } from "../../components/global/page-seo";

const CUSTOMER_RESET_PASSWORD = gql`
  mutation resetPasswordByUrl($resetUrl: URL!, $password: String!) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const Page = styled.div`
  padding: 0 50px;

  @media (max-width: 1445px) {
    padding: 0 40px;
  }

  @media (max-width: 999px) {
    padding: 0 30px;
  }

  @media (max-width: 800px) {
    &.page {
      margin: 220px 0 0 0;
    }

    padding: 0 25px;
  }

  & .left-column,
  & .right-column {
    width: 100%;

    @media (max-width: 760px) {
      max-width: 545px;
    }
  }

  @media (max-width: 800px) {
    & .left-column {
      margin: 0 0 100px 0;
    }
  }

  & .customer-fields-container {
    display: grid;
    grid-template-columns: minmax(325px, 545px) minmax(325px, 545px);
    grid-column-gap: 100px;
    margin: 110px 0 0 0;

    @media (max-width: 1445px) {
      grid-column-gap: 80px;
    }

    @media (max-width: 999px) {
      grid-column-gap: 30px;
    }

    @media (max-width: 800px) {
      grid-column-gap: 25px;
    }

    @media (max-width: 760px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & h3 {
      margin: 0 0 20px 0;
    }
  }
`;

const CustomerLoginContainer = styled.form`
  & .field {
    width: 100%;
    margin: 0 0 20px 0;

    & input {
      width: 100%;

      height: 50px;
      line-height: 50px;

      border: 1px solid #09184f;
      box-sizing: border-box;

      padding: 0 20px;

      ::-webkit-input-placeholder {
        color: #09184f;
      }
      ::-moz-placeholder {
        color: #09184f;
      }
      :-ms-input-placeholder {
        color: #09184f;
      }
      :-moz-placeholder {
        color: #09184f;
      }
    }
  }

  & button {
    &.account-button.login {
      width: 295px;
      height: 50px;

      font-size: 12px;

      background: #d3d3d3;
      color: #09184f;

      margin: 0 30px 0 0;

      text-transform: uppercase;

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0.2em;

    transition: text-decoration-color 300ms;

    &:hover {
      text-decoration-color: #09184f;
    }
  }
`;

const ResetPassword = ({ location }) => {
  const [newPasswordInput, setNewPasswordInput] = useState("");
  const [resetUrl, setResetUrl] = useState(null);
  const [customerResetByUrl, { data, loading, error }] = useMutation(
    CUSTOMER_RESET_PASSWORD
  );

  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  useEffect(() => {
    let url = new URL(location.href);
    let query = url.searchParams.get("reset_url");
    setResetUrl(query);
  }, [location]);

  return (
    <>
      <PageSeo
        title={`Réinitialisation du mot de passe`}
        image={null}
        description={null}
      />

      <Page className="page">
        <div className="title-container">
          <h1>Réinitialisation du mot de passe</h1>
        </div>

        <div className="customer-fields-container">
          <CustomerLoginContainer>
            <div className="field">
              <p>
                Veuillez saisir un nouveau mot de passe. Vous serez
                automatiquement redirigé vers la page Mon Compte une fois que
                votre mot de passe aura été réinitialisé. Nous vous avons envoyé
                un e-mail avec un lien pour mettre à jour votre mot de passe.
              </p>
            </div>

            <div className="field">
              <input
                className="input"
                value={newPasswordInput}
                type="password"
                onChange={(e) => setNewPasswordInput(e.target.value)}
                placeholder="New Password"
                required
              />
            </div>

            <div className="field">
              <button
                type="submit"
                disabled={resetUrl === null || newPasswordInput === ""}
                className="uppercase account-button login"
                onClick={(e) => {
                  e.preventDefault();
                  customerResetByUrl({
                    variables: {
                      resetUrl: resetUrl,
                      password: newPasswordInput,
                    },
                  }).then((result) => {
                    navigate(`/fr/account/login/`);
                  });
                }}
              >
                Envoyer
              </button>
            </div>
          </CustomerLoginContainer>
        </div>
      </Page>
    </>
  );
};

export default ResetPassword;
